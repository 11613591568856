import * as React from "react";
import {useMemo, useState} from "react";
import {useTemplatesQuery} from "../../data/api";
import {Spinner, SpinnerSize} from "@fluentui/react";
import TextInput from "../../components/inputs/textInput";
import TemplateCard from "../components/templateCard/card";

type TemplatesSearchModel = {
    title?: string
}
const Templates = () => {

    const templates = useTemplatesQuery()
    const [search, setSearch] = useState<TemplatesSearchModel>({});

    const onChangeSearch = (value: string, key: keyof TemplatesSearchModel) => {
        setSearch((s) => ({...s, [key]: value}))
    }
    const data = useMemo(() => {
        if (!templates.data)
            return []

        return templates.data
            .filter((i) => !Boolean(search.title) || i.name.includes(search.title))

    }, [search, templates.data])


    return (
        <div className={"pane"}>
            <div style={{marginTop: 13}}>
                <TextInput
                    placeholder={"Search"}
                    value={search.title}
                    onChangeText={(e) => onChangeSearch(e, 'title')}/>
            </div>
            {
                templates.isFetching && <Spinner size={SpinnerSize.large}/>
            }
            <div>

                {
                    data?.map(t => (<TemplateCard
                        titleIconUrl={"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Microsoft_Office_PowerPoint_%282019%E2%80%93present%29.svg/512px-Microsoft_Office_PowerPoint_%282019%E2%80%93present%29.svg.png?20210821050414"}
                        key={`template_${t.id}`}
                        templateUrl={t.url}
                        imageUrl={t.previewImageUrl}
                        title={t.name}
                        description={t.description}
                    />))
                }
            </div>
            <div style={{
                borderColor: "#dddddd80",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 2,
                marginTop: 33,
                marginBottom: 13,
                width: "100%",

            }}>
            </div>
        </div>
    );

}

export default Templates;
