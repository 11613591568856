import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'

export interface UserState {
    accessToken?: string | null

}

const initialState: UserState = {
    accessToken: null,
}

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        logout: state => {
            state.accessToken = null;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        },
    },
})

export const {logout, setAccessToken} = userSlice.actions

export default userSlice.reducer
