import {createApi} from "@reduxjs/toolkit/query/react";
import customFetchBase from "./middleware";
import {UserModel} from "../types/profile";
import {CreateSubscriberUser, SubscriberModel, UpdateUserModel} from "../types/subscription";


export const UserApi = createApi({
    baseQuery: customFetchBase,
    reducerPath: "auth",
    tagTypes: ["user", 'subscription'],
    endpoints: (builder) => ({
        profile: builder.query<UserModel, void>({
            providesTags: ["user"],
            query: () => ({
                url: 'users/me',
            })
        }),
        subscription: builder.query<SubscriberModel, void>({
            providesTags: ["subscription"],
            query: () => ({
                url: 'Subscribers/my',
            })
        }),
        addUserToSubscription: builder.mutation<{}, CreateSubscriberUser>({
            invalidatesTags: ["subscription"],
            query: body => ({
                url: `Subscribers/${body.subscriptionId}/user`,
                body: body,
                method: "POST"
            })
        }),
        removeUserToSubscription: builder.mutation<{}, { userId: number, subscriptionId: number }>({
            invalidatesTags: ["subscription"],
            query: body => ({
                url: `Subscribers/${body.subscriptionId}/removeuser`,
                body: {userId: body.userId},
                method: "POST"
            })
        }),
        updateUser: builder.mutation<{}, UpdateUserModel>({
            invalidatesTags: ["subscription"],
            query: request => ({
                url: `Subscribers/${request.id}/updateuser`,
                method: "POST",
                body: request
            })
        }),
        login: builder.mutation<{ jwt: string }, string>({
            invalidatesTags: ["user"],
            query: token => ({
                url: 'users/signin',
                method: "POST",
                body: {
                    aDAcccesToken: token
                }
            })
        }),
    })
});

export const {
    useUpdateUserMutation,
    useProfileQuery,
    useLoginMutation,
    useSubscriptionQuery,
    useAddUserToSubscriptionMutation,
    useRemoveUserToSubscriptionMutation
} = UserApi;
