import React from "react";
import {useSubscriptionQuery} from "../../../data/user";
import {toLocalDateTimeString} from "../../../utils/dateUtils";
import {Text} from "@fluentui/react/lib/Text";
import UsersTable from "./usersTable";

const SubscriptionAdministration: React.FC = () => {
    const {data} = useSubscriptionQuery()

    if (!data) {
        return <></>
    }
    return (<div className={"ms-depth-4"}
                 style={{
                     borderRadius: "2%",
                     overflow: "hidden",
                     marginTop: 13,
                     paddingInline: 8,
                     paddingBottom: 8,
                     paddingTop: 5,
                 }}>

        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
            <div>
                <Text variant={"large"}>
                    {data?.name}
                </Text>
            </div>
            <div>
                <Text variant={"medium"}>
                    Used {data?.users?.length} Out of {data?.numberOfUsersSubscribed} Subscription users
                </Text>
            </div>
        </div>

        <UsersTable/>

        <div>
            <Text variant={"small"}>
                Subscription Expiry Date:
            </Text> {toLocalDateTimeString(data?.subscriptionExpiryDate)}
        </div>

    </div>)
}
export default SubscriptionAdministration