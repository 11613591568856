import {createApi} from "@reduxjs/toolkit/query/react";
import customFetchBase from "./middleware";
import {LinkModel} from "../types/link";
import {TemplateModel} from "../types/template";


export const Api = createApi({
    baseQuery: customFetchBase,
    reducerPath: "data",
    tagTypes: [""],
    endpoints: (builder) => ({
        links: builder.query<Array<LinkModel>, void>({
            query: () => ({
                url: 'links',
            })
        }),
        fileBase64byUrl: builder.query<{ data: string }, string>({
            query: (e) => ({
                url: `blobs/getByUrl?BlobUrl=${e}`,
            })
        }),
        templates: builder.query<Array<TemplateModel>, void>({
            query: () => ({
                url: 'templates',
            })
        }),
    })
});

export const {
    useLazyFileBase64byUrlQuery,
    useLinksQuery,
    useTemplatesQuery
} = Api;
