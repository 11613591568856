import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import {DocumentCard, Spinner, SpinnerSize, Stack, StackItem} from "@fluentui/react";
import {Text} from "@fluentui/react/lib/Text";
import {getAccessToken} from "../../data/microsoftAuth";
import {useLoginMutation} from "../../data/user";
import {useDispatch} from "react-redux";
import {setAccessToken} from "../../data/userSlice";

const LoginScreen: React.FC = () => {

    const [clicked, setCLicked] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [login, loginResponse] = useLoginMutation()
    const d = useDispatch()
    const onClickLoginWithMicrosoft = async () => {
        if (loading)
            return
        setIsLoading(true)
        try {
            const at = await getAccessToken(true, (e) => {
                setError(JSON.stringify(e ?? {"Error": "Was unable to obtain an access token"}) + " CODE:111")
                setCLicked(true)
            })
            if (at) {
                login(at)
            }
        } catch (e) {
            setError("Something went wrong" + JSON.stringify(e ?? {"Error": "Authentication"}) + " CODE:121")
        }

        setIsLoading(false)
    }

    useEffect(() => {

        if (loginResponse.isSuccess) {
            d(setAccessToken(loginResponse.data.jwt))
        }

    }, [loginResponse.data, loginResponse.data]);

    useEffect(() => {
        // @ts-ignore
        if (loginResponse.isError) {
            try {
                // @ts-ignore
                setError(loginResponse?.error?.data?.AUTH_ERROR + " CODE:131")
            } catch (e) {
                console.log(e)
                setError("Something went wrong" + JSON.stringify(loginResponse.error) + " CODE:141")
            }
        }
    }, [loginResponse.error, loginResponse.isError]);


    return <div>

        <Header logo={require("./../../../assets/logo.png")} title={"title"} message="Welcome"/>

        <div style={{paddingInline: 13}}>
            <DocumentCard
                className={"ms-depth-4"}
                styles={{
                    root: {
                        borderRadius: "2%",
                        overflow: "hidden",
                        marginTop: 13,
                        paddingInline: 21,
                        paddingTop: 21,
                        paddingBottom: 21,
                    }
                }}
            >
                <Stack styles={{
                    root: {
                        paddingTop: 3,
                        paddingBottom: 5,
                    }
                }}>
                    <StackItem style={{
                        textAlign: "center"
                    }}>
                        <Text block variant={"large"} style={{fontWeight: "600", color: "#9437ff"}}>
                            Please Login Into Your Account
                        </Text>

                    </StackItem>
                    <StackItem style={{
                        marginTop: 33,
                        marginBottom: 13,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <img
                            onClick={onClickLoginWithMicrosoft}
                            alt={""}
                            className={"ms-depth-4"}
                            src={"https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_signin_light.svg"}/>

                    </StackItem>
                    <StackItem style={{
                        textAlign: "center"
                    }}>
                        {
                            error && <Text style={{color: "red"}}>
                                {/*You are not eligibile for this service*/}
                                {error}
                            </Text>
                        }

                    </StackItem>
                    <StackItem>
                        {
                            (loading || loginResponse.isLoading) && <Spinner size={SpinnerSize.large}/>
                        }
                    </StackItem>
                </Stack>
            </DocumentCard>

            <DocumentCard className={"ms-depth-4"}
                          styles={{
                              root: {
                                  borderRadius: "2%",
                                  overflow: "hidden",
                                  marginTop: 21,
                                  paddingInline: 21,
                                  paddingTop: 21,
                                  paddingBottom: 21,
                              }
                          }}>
                <Text block variant={"medium"}>
                    - ConsultingDIY offers business toolkits suitable for consultants, entrepreneurs and
                    consulting clients. Each toolkit is designed by experienced management consultants and
                    includes frameworks, modern visuals, tutorials, checklists, tips and valuable references.
                </Text>

                <Text style={{marginTop: 13}} block variant={"medium"}>
                    - Consultants could use these toolkits either to develop client deliverables or for training
                    purposes,
                    especially for junior resources that need more detailed guidance.

                </Text>
                <Text style={{marginTop: 13}} block variant={"medium"}>
                    - Entrepreneurs and consulting clients could use the toolkits to create consulting/business reports
                    in-house instead of hiring management consultants, or at least to reduce the cost and dependency on
                    external consultants.
                </Text>
            </DocumentCard>
        </div>


    </div>
}
export default LoginScreen