import * as React from "react";
import {Text} from '@fluentui/react/lib/Text';

import {
    DocumentCard,
    DocumentCardPreview,
    IDocumentCardPreviewProps, IImageProps,
    ImageFit,
    Stack, StackItem
} from "@fluentui/react";
import {useEffect, useState} from "react";
import {useLazyFileBase64byUrlQuery} from "../../../data/api";

type Props = {
    title: string
    titleIconUrl: string
    link?: string | null
    description?: string | null
}
export const LinkCard: React.FC<Props> = (props) => {


    const {title, link, description, titleIconUrl} = props
    //const [loading, setLoading] = useState(false);


    const onClick = async () => {

        if (link) {
            window.open(link)
        }
    }

    return (
        <DocumentCard
            onClick={onClick}
            className={"ms-depth-4"}
            styles={{
                root: {
                    borderRadius: "2%",
                    overflow: "hidden",
                    marginTop: 13,
                    paddingInline: 21,
                    paddingTop: 8,
                    paddingBottom: 8,
                }
            }}
        >
            <div>


            </div>
            <Stack styles={{
                root: {
                    paddingTop: 3,
                    paddingBottom: 5
                }
            }}>
                {title && <StackItem style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,

                }}>
                    <img
                        src={titleIconUrl}
                        alt=""
                        height={29}
                    />
                    <Text nowrap block variant={"large"} style={{fontWeight: "600"}}>
                        {title}
                    </Text>
                </StackItem>}

                {description && <StackItem style={{marginTop: 13}}>
                    <Text block variant={"mediumPlus"}>
                        {description}
                    </Text>
                </StackItem>}

                <StackItem>
                    <div style={{
                        borderColor: "#dddddd80",
                        borderStyle: "solid",
                        borderWidth: 0,
                        borderBottomWidth: 2,
                        marginTop: 18,
                        marginBottom:13,
                        width: "106%",
                        marginLeft:"-3%"
                    }}>
                    </div>
                </StackItem>

                <StackItem style={{flexDirection: "row-reverse", display: "flex",}}>
                    <Text block variant={"mediumPlus"} style={{color: "#9437ff",fontWeight:"500"}}>
                        Open
                    </Text>
                </StackItem>

            </Stack>


        </DocumentCard>

    )
};

export default LinkCard