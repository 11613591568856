import React from "react";
import {TextField} from "@fluentui/react";

type Props = {
    value: string | undefined
    onChangeText: (value: string) => void
    label?: string
    errorMessage?: string
    placeholder?: string
    type?: "number" | "text" | "email"
    invalid?: boolean
    errorText?: string
}
const TextInput: React.FC<Props> = (props) => {
    const {label, value, onChangeText, errorMessage, placeholder, type = "text", invalid = false, errorText} = props
    
    const onChange = React.useCallback(
        (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            onChangeText(newValue);
        },
        [],
    );

    return <div style={{paddingTop: 5, paddingBottom: 5}}>
        <TextField
            type={type}
            label={label}
            value={value}
            placeholder={placeholder}
            errorMessage={(invalid) && errorMessage}
            onChange={onChange}
            invalid={invalid}
        />
    </div>
}

export default TextInput