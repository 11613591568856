import 'babel-polyfill';
import App from "./App";
import {AppContainer} from "react-hot-loader";
import {initializeIcons} from "@fluentui/font-icons-mdl2";
import {createTheme, ThemeProvider} from "@fluentui/react";
import * as React from "react";
import Store from "../data/store";
import {Provider} from "react-redux";
import {createRoot} from "react-dom/client";

initializeIcons();
const myTheme = createTheme({
    palette: {
        themePrimary: '#9437ff',
        themeLighterAlt: '#fbf7ff',
        themeLighter: '#eedfff',
        themeLight: '#dfc3ff',
        themeTertiary: '#bf88ff',
        themeSecondary: '#a250ff',
        themeDarkAlt: '#8632e6',
        themeDark: '#712bc2',
        themeDarker: '#531f8f',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralSecondaryAlt: '#8a8886',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

let isOfficeInitialized = false;

const title = "ConsultingDIY";

const render = (Component) => {

    const container = document.getElementById("container") as Element;
    const root = createRoot(container!);
    root.render(<AppContainer>
        <ThemeProvider theme={myTheme}>
            <Provider store={Store}>
                <Component title={title} isOfficeInitialized={isOfficeInitialized}/>
            </Provider>
        </ThemeProvider>
    </AppContainer>);
    // ReactDOM.render(
    //   <AppContainer>
    //     <ThemeProvider theme={myTheme}>
    //       <Provider store={Store}>
    //       <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    //       </Provider>
    //     </ThemeProvider>
    //   </AppContainer>,
    //   document.getElementById("container")
    // );
};

/* Render application after Office initializes */
Office.onReady(() => {
    isOfficeInitialized = true;
    render(App);

});

if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
        const NextApp = require("./App").default;
        render(NextApp);
    });
}
