import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError,} from '@reduxjs/toolkit/query';
import {RootState} from "./store";

//const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: `https://app.consultingdiy.com/api/`,
    //baseUrl: ` https://localhost:7252/api/`,
    prepareHeaders: (headers, {getState}) => {
        const token = (getState() as RootState).userSlice.accessToken;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

const customFetchBase: BaseQueryFn<string | FetchArgs,
    any,
    FetchBaseQueryError> = async (args, api, extraOptions) => {


    return await baseQuery(args, api, extraOptions) as any;
};

export default customFetchBase;