import React, {useEffect} from "react";
import {DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton} from "@fluentui/react";
import {UserModel} from "../../../types/profile";
import {useRemoveUserToSubscriptionMutation, useSubscriptionQuery} from "../../../data/user";


const dialogContentProps = {
    type: DialogType.normal,
    title: 'Remove ',
    closeButtonAriaLabel: 'Close',
    subText: 'Do you want to send this message without a subject?',
};

type Props = {
    hidden: boolean

    onClose: () => void
    member: UserModel
}
const RemoveUserDialog: React.FC<Props> = ({onClose, hidden, member}) => {
    const [removeUser, removeUserResponse] = useRemoveUserToSubscriptionMutation()
    const {data} = useSubscriptionQuery()

    useEffect(() => {
        if (removeUserResponse.isSuccess) {
            onClose()
        }
    }, [removeUserResponse.isSuccess])
    const onRemoveMember = () => {

        removeUser({userId: member.id, subscriptionId: data.id})
    }
    return <Dialog
        hidden={hidden}
        onDismiss={onClose}
        dialogContentProps={{
            type: DialogType.normal,
            title: 'Removing a member',
            closeButtonAriaLabel: 'Close',
            subText: `Are you sure that you want to remove ${member.firstName} ${member.lastName} from this subscription`,
        }}
        //   modalProps={modalProps}
    >
        <DialogFooter>
            <PrimaryButton onClick={onRemoveMember} text="Remove"/>
            <DefaultButton onClick={onClose} text="Close"/>
        </DialogFooter>
    </Dialog>
}

export default RemoveUserDialog