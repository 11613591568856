import {useSubscriptionQuery} from "../../../data/user";
import React, {useState} from "react";
import {Persona, PersonaSize, PrimaryButton} from "@fluentui/react";
import {AiFillEdit, AiTwotoneDelete} from "react-icons/ai";
import AddMemberPanel from "./addMemberPanel";
import {UserModel} from "../../../types/profile";
import RemoveUserDialog from "./removeUserDialog";
import UpdateMemberPanel from "./updateMemberPanel";

const UsersTable: React.FC = () => {
    const {data} = useSubscriptionQuery()
    const [openPanel, setOpenPanel] = useState<"NONE" | "ADD_MEMBER" | "REMOVER_MEMBER" | "EDIT_MEMBER">("NONE");
    const [userForAction, setUserForAction] = useState<null | UserModel>(null);

    const onClose = () => {
        setOpenPanel("NONE")
        setUserForAction(null)
    }
    return <div style={{marginTop: 13, marginBottom: 13}}>

        <AddMemberPanel visible={openPanel === "ADD_MEMBER"} onClose={onClose}/>
        {userForAction &&
            <RemoveUserDialog onClose={onClose} member={userForAction} hidden={openPanel !== "REMOVER_MEMBER"}/>
        }
        {
            userForAction &&
            <UpdateMemberPanel onClose={onClose} visible={openPanel === "EDIT_MEMBER"} user={userForAction}/>
        }
        <div style={{display: "flex", flexDirection: "row-reverse"}}>
            <PrimaryButton width={""} text="AddUser"
                           disabled={data && data?.users?.length === data?.numberOfUsersSubscribed}
                           onClick={() => {
                               setOpenPanel("ADD_MEMBER")
                           }}/>
        </div>

        <div style={{display: "flex", gap: 8, flexDirection: "column"}}>
            {
                data?.users?.map(user => <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{width: "95%"}}>
                            <Persona
                                styles={{
                                    root: {
                                        minHeight: '60px'
                                    },
                                    primaryText: {
                                        fontSize: 16
                                    }
                                }}
                                coinSize={32}
                                size={PersonaSize.size72}
                                secondaryText={user.email}
                                tertiaryText={user.userScope}
                                text={`${user.firstName} ${user.lastName}`}
                                title={`${user.firstName} ${user.lastName}`}
                            />
                        </div>
                        <div>
                            <AiTwotoneDelete onClick={() => {
                                setUserForAction(user)
                                setOpenPanel("REMOVER_MEMBER")
                            }} color={"#dd1111"}/>
                            <AiFillEdit onClick={() => {
                                setUserForAction(user)
                                setOpenPanel("EDIT_MEMBER")
                            }}/>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
}
export default UsersTable