import * as React from "react";
import {DefaultButton, IPersonaSharedProps, Persona, PersonaInitialsColor, Spinner, SpinnerSize} from '@fluentui/react';
import {useProfileQuery} from "../../data/user";
import {useDispatch} from "react-redux";
import {logout} from "../../data/userSlice";
import SubscriptionAdministration from "./components/subscriptionAdministration";

const Account = () => {
    const profile = useProfileQuery()
    const d = useDispatch()
    const persona: IPersonaSharedProps = {
        text: `${profile.data?.firstName ?? ''} ${profile.data?.lastName ?? ''}`,
        secondaryText: profile.data?.email ?? '',
    };


    console.log(profile.data)
    return (
        <div style={{
            paddingTop: 21,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
        }}>
            <div>
                {
                    profile.isFetching && <Spinner size={SpinnerSize.large}/>
                }

                {
                    profile.data &&
                    <Persona {...persona} initialsColor={PersonaInitialsColor.teal} coinSize={52}/>

                }
            </div>

            {
                profile.data?.userScope === "SubscriberAdmin" && <SubscriptionAdministration/>
            }

            <div style={{marginTop: 21}}>
                <DefaultButton width={""} text="Logout" onClick={() => {
                    d(logout())
                }}/>

            </div>

        </div>
    );

}

export default Account;
