import * as React from "react";
import {useLinksQuery} from "../../data/api";
import {Spinner, SpinnerSize} from "@fluentui/react";
import LinkCard from "../components/linkCard/card";


const Links = () => {
    const links = useLinksQuery()
    return (
        <div className={"col"} style={{position: "relative"}}>
            {
                links.isFetching &&
                <div style={{position:"absolute",top:150,left:"45%"}}><Spinner size={SpinnerSize.large}/></div>
            }
            {
                links?.data?.map(l => (
                    <LinkCard
                        titleIconUrl={"https://cdn2.iconfinder.com/data/icons/pittogrammi/142/95-512.png"}
                        key={`template_${l.id}`}
                        title={l.name}
                        description={l.description}
                        link={l.url}
                    />
                ))
            }
            <div style={{
                borderColor: "#dddddd80",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 2,
                marginTop: 33,
                marginBottom:13,
                width: "100%",

            }}>
            </div>
        </div>
    );

}

export default Links;
