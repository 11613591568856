import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {useDispatch} from 'react-redux';
import {Api} from "./api";
import {UserApi} from "./user";
import UserSlice from "./userSlice";

const reducers = combineReducers({
    [Api.reducerPath]: Api.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    userSlice: UserSlice
});

const Store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([Api.middleware, UserApi.middleware]),
})

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default Store;