import * as React from "react";

export interface HeaderProps {
    title: string;
    logo: string;
    message: string;
}

export default class Header extends React.Component<HeaderProps> {
    render() {
        const {title, logo, message} = this.props;

        return (
            <section className=" ms-bgColor-neutralLighter ms-u-fadeIn500 py-5">
                <img width={"66%"} style={{padding:13}} src={logo} alt={title} title={title}/>
                {/* <h1 className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary">{message}</h1> */}
            </section>
        );
    }
}
