import * as React from "react";
import Header from "./components/Header";
import {Pivot, PivotItem, ScrollablePane, Spinner, SpinnerSize} from '@fluentui/react';
import Templates from "./templates/templates";
import Links from "./links/links";
import Account from "./account/account";
import {useIsLoggedIn} from "../data/hooks";
import LoginScreen from "./auth/login";
import {useProfileQuery} from "../data/user";

export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
}

const pivotItemStyle = ({
    paddingInline: 18,
});
const App: React.FC<AppProps> = ({isOfficeInitialized, title}) => {

    const isLoggedIn = useIsLoggedIn()
    const profile = useProfileQuery()
    if (!isOfficeInitialized || profile.isFetching && !profile.error) {
        return <div><Spinner size={SpinnerSize.large}/></div>
    }


    return (
        <div className="ms-welcome">
            
            {
                !isLoggedIn && <LoginScreen/>
            }
            {
                isLoggedIn && <ScrollablePane
                    scrollContainerFocus={true}
                    scrollContainerAriaLabel="Sticky component example"
                    // styles={scrollablePaneStyles}
                >

                    <Header logo={require("./../../assets/logo.png")} title={title} message="Welcome"/>
                    <Pivot styles={{
                        root: {
                            backgroundColor: "#bbb",

                        },

                        text: {
                            color: "#fff"
                        }

                    }}>
                        <PivotItem
                            headerText="My Templates"
                            style={pivotItemStyle}
                            headerButtonProps={{
                                'data-order': 1,

                            }}
                        >
                            <Templates/>
                        </PivotItem>
                        <PivotItem style={pivotItemStyle}

                                   headerText="Links">
                            <Links/>
                        </PivotItem>
                        <PivotItem style={pivotItemStyle}

                                   headerText="Account">
                            <Account/>
                        </PivotItem>
                    </Pivot>
                </ScrollablePane>
            }

        </div>

    );
}


export default App