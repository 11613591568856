export const getAccessToken = async (allowSignInPrompt: boolean, onError?: (str: string) => void): Promise<string | null> => {

    let token = ""
    try {
        token = await Office.auth.getAccessToken({
            allowSignInPrompt: allowSignInPrompt,
            allowConsentPrompt: allowSignInPrompt,
        });
    } catch (exception) {
        onError?.(exception)
        return null
    }
    return token
}