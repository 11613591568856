import {ChoiceGroup, Panel, PrimaryButton, Spinner, SpinnerSize} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import TextInput from "../../../components/inputs/textInput";
import {CreateSubscriberUser} from "../../../types/subscription";
import {isValidEmail, isValidNumber} from "../../../utils/validations";
import {useAddUserToSubscriptionMutation, useSubscriptionQuery} from "../../../data/user";

type Props = {
    onClose: () => void
    visible: boolean
}
const AddMemberPanel: React.FC<Props> = ({visible, onClose}) => {
    const {data} = useSubscriptionQuery()

    const [touched, setTouched] = useState<Array<keyof CreateSubscriberUser>>([]);
    const [addUser, addUserResponse] = useAddUserToSubscriptionMutation()

    const [member, setMember] = useState<CreateSubscriberUser>({
        email: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        scopeType: "SubscriberUser",
        subscriptionId: data.id
    });
    useEffect(() => {
        if (addUserResponse.isSuccess) {
            onClose()
        }
    }, [addUserResponse.isSuccess])

    const onChangeMember = (value: string, key: keyof CreateSubscriberUser) => {
        if (!touched.includes(key)) {
            setTouched([...touched, key])

        }
        setMember((e) => ({...e, [key]: value}))
    }

    const onSubmit = () => {

        setTouched(Object.keys(member) as Array<keyof CreateSubscriberUser>)
        if (!Object.entries(member).some(i => !Boolean(i)) && isValidEmail(member.email) && isValidNumber(member.mobileNumber)) {
            addUser(member)

        }
    }
    return <Panel
        headerText="Add Member"
        isOpen={visible}
        onDismiss={onClose}
        closeButtonAriaLabel="Close"
    >
        <div>
            <TextInput
                placeholder={"First name"}
                label={"Last name"}
                invalid={(() => {

                    return !Boolean(member.firstName) && touched.includes('firstName')
                })()}
                errorMessage={"Required"}
                value={member.firstName}
                onChangeText={(e) => onChangeMember(e, 'firstName')}/>

            <TextInput
                placeholder={"Last name"}
                label={"Last name"}
                invalid={(() => {
                    return !Boolean(member.lastName) && touched.includes('lastName')
                })()}
                errorMessage={"Required"}
                value={member.lastName}
                onChangeText={(e) => onChangeMember(e, 'lastName')}/>
            <TextInput
                invalid={(() => {
                    return touched.includes('email') && (!Boolean(member.email) || !isValidEmail(member.email))
                })()}
                errorMessage={"Please enter a valid email"}
                placeholder={"Email address"}
                label={"Email address"}
                value={member.email}
                onChangeText={(e) => onChangeMember(e, 'email')}/>
            <TextInput
                invalid={(() => {
                    return touched.includes('mobileNumber') && (!Boolean(member.mobileNumber) || !isValidNumber(member.mobileNumber))
                })()}
                errorMessage={"Please enter a valid mobile number"}
                type={"number"}
                placeholder={"Mobile"}
                label={"Mobile"}
                value={member.mobileNumber}
                onChangeText={(e) => {

                    onChangeMember(e, 'mobileNumber')

                }}/>

            <ChoiceGroup
                selectedKey={member.scopeType}
                label="Role"
                onChange={(_: never, e) => onChangeMember(e.key as "SubscriberUser" | "SubscriberAdmin", 'scopeType')}
                options={[
                    {
                        text: "Regular User",
                        key: "SubscriberUser"
                    },
                    {
                        text: "Admin",
                        key: "SubscriberAdmin"
                    }
                ]}/>

            <PrimaryButton width={""} text="Save"
                           style={{width: "100%", marginTop: 13}}
                           onClick={onSubmit}/>
            {
                addUserResponse.isLoading &&
                <div style={{position: "absolute", top: 150, left: "45%"}}><Spinner size={SpinnerSize.large}/></div>
            }
        </div>

    </Panel>
}
export default AddMemberPanel