import * as React from "react";
import {useState} from "react";
import {Text} from '@fluentui/react/lib/Text';

import {
    DocumentCard,
    DocumentCardPreview,
    IDocumentCardPreviewProps,
    ImageFit,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem
} from "@fluentui/react";

type Props = {
    title: string
    titleIconUrl: string
    description?: string | null
    imageUrl?: string | null
    templateUrl?: string | null
}

const downloadAndConvert = async (url: string) => {
    try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const base64 = arrayBufferToBase64(arrayBuffer);
        return base64;
    } catch (error) {
        console.error(error);
        return null
    }
}

const arrayBufferToBase64 = async (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const TemplateCard: React.FC<Props> = (props) => {


    const {title, description, imageUrl, templateUrl, titleIconUrl} = props
    const [loading, setIsLoading] = useState(false);


    const previewProps: IDocumentCardPreviewProps = {
        previewImages: [
            {
                previewImageSrc: imageUrl,
                imageFit: ImageFit.centerCover,
                width: 400,
                height: 200,
            },
        ],
    };
    // useEffect(() => {
    //
    //     console.log(res.status, "response API", new Date().toISOString())
    //     if (res.data?.data && res.status == "fulfilled") {
    //         console.log("before open", new Date().toISOString())
    //         save(res.data.data).then()
    //     }
    // }, [res.data, res.status])
    // const clear = async () => {
    //     await PowerPoint.run(async function (context) {
    //         const slidesCount = context.presentation.slides.getCount()
    //         await context.sync();
    //
    //         for (let i = 0; i < slidesCount.value; i++) {
    //             const slide = context.presentation.slides.getItemAt(i)
    //             slide.delete()
    //         }
    //         await context.sync();
    //     });
    // }
    const onClick = async () => {

        setIsLoading(true)
       
        if (templateUrl) {
            const data = await downloadAndConvert(templateUrl)
            if (data) {
                await save(data);
            } else {
                console.log("fAILED TO FETCH DATA")

            }
        }
        setIsLoading(false)

    }


    const save = async (file: string) => {
        console.log("opening ", new Date().toISOString())
        try {
            await PowerPoint.createPresentation(file)
            console.log("opened ", new Date().toISOString())
        } catch (e) {
            console.log("error ", e, new Date().toISOString())

        }
    }

    return (
        <DocumentCard
            onClick={onClick}
            styles={{
                root: {
                    position: "relative",
                    borderRadius: "1%",
                    overflow: "hidden",
                    marginTop: 21,
                    paddingBottom: 5,
                    boxShadow: "0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)"
                }
            }}
        >
            {props.imageUrl && <DocumentCardPreview {...previewProps} />}
            {
                (loading) && <div style={{position: "absolute", zIndex: 1000, left: "45%", bottom: "13%"}}><Spinner
                    size={SpinnerSize.large}/></div>
            }
            <div>


            </div>
            <Stack styles={{
                root: {
                    paddingTop: 13,
                    paddingInline: 13,
                    paddingBottom: 8,
                }
            }}>
                {title && <StackItem style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",

                }}>


                    <Text nowrap block style={{fontWeight: "600", color: "#9437ff"}} variant={"mediumPlus"}
                    >
                        {title}
                    </Text>
                </StackItem>}

                {description && <StackItem>
                    <Text block style={{fontWeight: "600", marginTop: 5}} variant={"medium"}>
                        {description}
                    </Text>
                </StackItem>}

            </Stack>


        </DocumentCard>

    )
};

export default TemplateCard